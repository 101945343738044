/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track { position: relative; left: 0; top: 0; display: block; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
}
[dir="rtl"] .slick-slide { float: right; }
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Icons */
/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 20px; width: 20px; line-height: 0; font-size: 0; cursor: pointer; background: transparent; color: transparent; top: 50%; margin-top: -10px; padding: 0; border: none; outline: none; }
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev { left: -25px; }
[dir="rtl"] .slick-prev { left: auto; right: -25px; }
.slick-prev:before { content: "←"; }
[dir="rtl"] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }
[dir="rtl"] .slick-next { left: -25px; right: auto; }
.slick-next:before { content: "→"; }
[dir="rtl"] .slick-next:before { content: "←"; }

/* Dots */
.slick-slider { margin-bottom: 30px; }

.slick-dots { position: absolute; bottom: -45px; list-style: none; display: block; text-align: center; padding: 0; width: 100%; }
.slick-dots li { position: relative; display: inline-block; height: 20px; width: 20px; margin: 0 5px; padding: 0; cursor: pointer; }
.slick-dots li button:hover, .slick-dots li button:focus { outline: none; }
.slick-dots li button:hover:before, .slick-dots li button:focus:before { opacity: 1; }
.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "•"; width: 20px; height: 20px; text-align: center; color: black; opacity: 0.25; }
.slick-dots li.slick-active button:before { color: black; opacity: 0.75; }

.slick-slider {
  margin: 0;
  font-size: 1rem;
  width: 60rem;

  .slick-slide {
    //padding: 10px 35px;
    min-height: 54px;

    &.warning-bgcolor {
      background-color: #fff8e1;
    }

    &.alarm-bgcolor {
      background-color: #ffe7e7;
    }

    .pull-left {
      float: left;
    }

    .pull-right {
      float: right;
    }
    .icon {
      @extend %icon-min;
    }
    .through {
      text-decoration: line-through;
      color: #979AA2;
    }

    &.canceled {
      .time-items {
        span {
          text-decoration: line-through;
        }
      }
    }
  }

  .slick-next, .slick-prev {
    position: absolute;
    margin: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 21px;

    &:after {
      content: '';
      width: 7px;
      height: 9px;
      position: absolute;
      top: 50%;
      margin-top: -4px;
    }

    &.slick-disabled {
      display: none !important;
    }
  }
  .slick-prev {
    left: 0;

    &:after {
      right: 0;
    }
  }
  .slick-next {
    right: 0;

    &:after {
      left: 0;
      background-position: -174px -132px;
    }
  }
  .slick-dots {
    display: none !important;
  }
}
.time-items{
  //padding-left: 23rem;
}
