/* *{
    padding:0;
    margin:0;
    box-sizing: border-box;
} */
.onboarding-wrapper{
    padding: 50px 15px;
    background-color: #F0F3F8;
    width:100%;
     height:100vh;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-align: center;
}
.onboarding-title{
    font-size: 4rem;
    font-weight: 700;
    color: #6F7B94;
}
.onboard-img{
    margin: 3.1rem auto;
    width: 18.75rem ;
}
.desc{
    font-size: 2.1rem;
    color: #6F7B94;
    margin-bottom: 3.1rem;
}
.btnn {
    height: 3.75rem !important;
    width: 18.75rem !important;
    border-radius: 12px !important;
    background-color: #4C93FF !important;
    box-shadow: 0 10px 20px 0 rgba(76,147,255,0.4) !important;
    border: none;
}
.arr_cls{
    font-size: 10px !important;
    padding:5px !important;
    margin-bottom: 2px !important;
    padding-top: 2px !important;
    color: white;
}
