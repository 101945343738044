.terms-conditions {
  /*height: 87px;*/
  width: 100%;
  /* height: 100vh; */
  color: #6F7B94;
  font-family: "Open Sans";
  font-size: 64px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 87px;
  text-align: center;
  padding-top: 59px;
  padding-bottom: 60px;
}
/*.container{*/
  /*height: 100vh;*/
/*}*/
.contain{
  height: 100vh;
}
.body {
    position: relative;
    background: #F0F3F8;
    height: 100vh;
  }
ul.nav-pills {
    top: 20px;
    position: fixed;
  }
  /*div.col-sm-12 div {
    height: 250px;
    font-size: 28px;
  }*/

.butn{
    display: flex;
  	/* float: right; */
    /* justify-content: right; */
  	border-radius: 5px;
  	/* margin-top: 2%; */
  	border: none;
  	text-align: end;
    padding-top: 21px;


  }
.section1 {

  height: 331px;
  /*width: 100px;*/
  color: #8E8F93;
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 2%;

}
.section1 h4{
  font-size: 18px !important;
  padding-top: 22px;
  padding-bottom: 14px;
	font-weight: bold;
}
/*.background-1{

  height: 380px;*/
  /*width: 1240px;*/
  /*border-radius: 18px;
  background-color: #FFFFFF;
  box-shadow: 0 12px 18px 0 rgba(41,94,255,0.05);
}*/
.disagree{
	height: 60px;
    width: 160px;
    border: none;
    border-radius: 13px;
    background: #DFDFDF;
    margin-right: 2%;
    color: #595B60;
    font-family: Rubik;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center
}
.accept{
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* display: flex; */
	height: 60px;
	width: 300px;
	border: none;
	border-radius: 12px;
	background:#BBBBBB;
	margin-right: -50%;
	text-align: center;
  letter-spacing: 0;
  /* line-height: 24px !important; */
    /* line-height: 1em !important;   */
  font-size: 20px;
  font-family: "Rubik";
  padding-left: 6%;
  padding-top: 9px;
  position: relative;
}
.accept span{
	height: 27px;
  /*width: 300px;*/
  color: #FFFFFF;
  font-family: "Rubik";
  font-size: 20px;
  letter-spacing: 0;
  margin-bottom: 2px !important;
  /*line-height: 27px;*/
  /*text-align: center;*/
   /*display: block;*/
    /*padding-top: 22px;*/
  text-align: center;
    /* line-height: 1em; */
}


.vertical-scrollable {
          /*position: absolute; */
          /*top: 120px; */
          bottom: 100px;
          /*left: 180px; */
          /*width: 50%; */
          overflow-y: scroll;
          background: #ffffff;
          border-radius: 18px;
          scrollbar-width: thin !important;
        }


  /* .vertical-scrollable::-webkit-scrollbar {
    width: 3px !important;
  }
  .vertical-scrollable::-webkit-scrollbar-track {
    border-radius: 18px !important;
    color: coral !important;
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  /* }
  .vertical-scrollable::-webkit-scrollbar-thumb {
    background: #DFDFDF !important;
    border-radius: 10px !important; */
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  /* } */
  .iconCol{
	margin-left: 13%;
  margin-bottom: 10% !important;
  width: 8%;
  margin-top: 0%;
  padding-bottom: 2px;

}
.arrow-right:after {
  content: "";
    display: inline-block !important;
    width: 0;
    height: 0;
    border-left: 5px solid #fff;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    vertical-align: middle;

}

.arrow-right:before {
  width: 7px !important;
  height: 2.2px;
  background: #ffffff;
  content: "";
  display: inline-block;
  vertical-align: middle;
}
.arr-cl{
  /* border: 1px solid #595B60 !important; */
    border-radius: 50%;
    font-size: 14px;
    background: #595B60;
    /* padding: 5px !important; */
    /* padding-top: 2px  !important; */
    margin-left: 0 !important;
    margin-bottom: 2px !important;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  @media screen and (max-width: 810px) {
    .section1   {
      /*margin-left: 150px;*/
    }
  }
  @media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  color: #4C93FF !important;
}
.accept title {
  height: 19px;
  width: 150px;
  color: #4C93FF !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 19px !important;
  text-align: center;
  background-color: coral !important;

  /* width: 120px; */
  /* background-color: black; */
  /* color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0; */

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
/* .arr_cls{
  font-size: 10px !important;
  padding:5px !important;
  margin-bottom: 2px !important;
  padding-top: 3px !important;
} */
.fa_customs{
float: right;
padding-right: 42px;
padding-top: 0px !important;
}
.popover-info{
  color: #4C93FF;
  font-size: 14px;
}
.vertical-scrollable::-webkit-scrollbar {
  width: 6px;
}
.vertical-scrollable::-webkit-scrollbar-track {
  background: #F0F3F8;
    margin: 20px;
}
.vertical-scrollable::-webkit-scrollbar-thumb {
  background: #DFDFDF;
}
